import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION
import { Error403View } from "../error-403-view/Error403View.jsx";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";
import { ToastNotification } from "../../components/toast-notification/ToastNotification.jsx";
import { SearchSeguimiento } from "../../components/logistic-components/SearchSeguimiento.jsx";
import { ModalUpdateOrder } from "../../components/seguimiento-logistica/ModalUpdateOrder.jsx";
import { Add } from "../../components/icons/AppIcons.jsx";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions.js";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// React translation
import { useTranslation } from "react-i18next";

// lang context
import { LangContext } from "../../context/LangContext.jsx";
import { useFetchFollowUp } from "../../components/customs/seguimiento-logistica/useFetchFollowUp.js";





export const SeguimientoLogistica = () => {


  // GET react translation
  const [t] = useTranslation("global");


  // GET useSearchParams
  const [params] = useSearchParams();


  const { lang } = useContext(LangContext);


  // state definition
  const [paramsState] = useState({ zona: params.get("nom_area"), etapa: params.get("nom_step"), suplidor: params.get("nom_suppl"), numOrden: params.get("num_ord"), numPart: params.get("num_part"), soNumber: params.get("num_so"), guiaDesp: params.get("num_tracking"), fecDesp: params.get("fec_desp"), division: params.get("nom_divi"), linea: params.get("nom_lin"), modalidad: params.get("nom_moda"), cliente: params.get("nom_cli"), workflow: params.get("workflow"), filter: params.get("filt") });


  // custom hooks
  const { state,
    orderList,
    orderUpdate,
    pageSize,
    zonas,
    handleChangeMove,
    handleCloseOpenSearch,
    handleChangePartVal,
    handleCloseOpenModalUpd,
    handleSendServer,
    handleSetNewState,
    handleCloseOpenBtnAdd,
    handleSetNewOrderUpdate,
    fethDataServer,
    handleChangeUpdate,
    setNewValForParts } = useFetchFollowUp(urls, getTheToken().token, lang, paramsState);

  // navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();



  return (
    <>
      {
        // unauthorized component
        state.unauthorized && (<Error403View />)
      }
      {/* MODAL PARA FILTRO */}
      {
        state.modalSearch && (
          <SearchSeguimiento
            onClose={handleCloseOpenSearch}
            token={getTheToken().token}
            lang={lang}
            pageSize={pageSize}
            setNewState={handleSetNewState} />
        )
      }
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        <p className="m-y-small">
          <strong className="border-record">{orderList.length} de {state.total} ordenes</strong>
        </p>
        {
          state.dataVisible && (
            <>
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Temp</th>
                      <th>{t("TableParts.orden")}</th>
                      <th>{t("TableParts.solicitud")}</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.cliente")}</th>
                      <th>{t("TableParts.suplidor")}</th>
                      <th>{t("TableParts.datos")}</th>
                      <th>{t("TableParts.etapa")}</th>
                      <th>Sel</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      orderList && orderList.map((order) => (
                        <tr key={`${order.CodSolRepuesto}-${order.CodDetSolRepuesto}`}>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <span className={renderTemperature(order.Prioridad)}></span>
                            </div>
                          </td>
                          <td className="txt-center">
                            <Link className="link-tag" to={`/logistic/order-detail-reader/${order.NumOrden}`}>{order.NumOrden}</Link>
                          </td>
                          <td className="txt-center">
                            <Link className="link-tag" to={`/request-part-detail/${order.CodSolRepuesto}`}>{order.CodSolRepuesto}</Link>
                          </td>
                          <td style={{ textAlign: 'center' }}>{order.Cantidad}</td>
                          <td>
                            <Link className="link-tag" to={`/part-detail/${order.CodDetSolRepuesto}/${order.CodSolRepuesto}`}>{order.NumParte}</Link>
                          </td>
                          <td>{order.Descripcion}</td>
                          <td>{order.NomCliente}</td>
                          <td>{order.NomProveedor}</td>
                          <td className="col-7">
                            <span style={{ display: 'block' }}><strong>{t("TableParts.precio")}:</strong> {formatCurrency(order.Precio, 'USD')}</span>
                            <span style={{ display: 'block' }}><strong>WorkFlow:</strong> {order.WorkFlow}</span>
                            <span style={{ display: 'block' }}>{order.SO !== null && (<><strong>SO/SOA:</strong> {order.SO}</>)}</span>
                            <span style={{ display: 'block' }}>{order.GuiDespacho !== null && (<><strong>{t("RequestPartsView.guia")}:</strong> {order.GuiDespacho}</>)}</span>
                            <span style={{ display: 'block' }}>{order.FecDespacho !== null && (<><strong>{t("RequestPartsView.fec_despa_act")}:</strong> {renderDate(order.FecDespacho, lang)}</>)}</span>
                          </td>
                          <td>{order.NomEtapa}</td>
                          <td className="txt-center">
                            {order.IdEtapa !== 99 && (
                              <input
                                className="input-control-checkbox"
                                type="checkbox"
                                name={`action-${order.CodDetSolRepuesto}`}
                                checked={order.Mover}
                                onChange={({ target }) => handleChangeMove(order.CodDetSolRepuesto, order.CodSolRepuesto, target.checked)} />
                            )}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones para el paginado */}
              {
                orderList && orderList.length > 0 && (
                  <div className="row-flex m-y-medium align-c justify-center">
                    {
                      state.moreData ? <span className="required">{t("serviceCard.no_mas_datos")}</span> : <button type="button" className="btn-primary" onClick={() => fethDataServer("1")}>{t("serviceCard.ver_mas")}</button>
                    }
                  </div>
                )
              }

              {/* Button de search */}
              <ButtonSearch onOpenFilter={handleCloseOpenSearch} />

              {/* Boton para agregar las partes */}
              {
                state.showBtnAdd && (
                  <div className="btn-add-part-container">
                    <button className="btn-add-part" onClick={handleCloseOpenModalUpd}>
                      <Add />
                    </button>
                  </div>
                )
              }

              {/* Modal para actualizar */}
              {
                state.showModalUpdate && (
                  <ModalUpdateOrder
                    onClose={handleCloseOpenModalUpd}
                    orderUpdate={orderUpdate}
                    urls={urls}
                    token={getTheToken().token}
                    sendServer={handleSendServer}
                    handleChangePartVal={handleChangePartVal}
                    onCloseBtn={handleCloseOpenBtnAdd}
                    setNewOrderUpd={handleSetNewOrderUpdate}
                    changeUpdate={handleChangeUpdate}
                    state={state}
                    zonas={zonas}
                    setNewStateParts={setNewValForParts} />
                )
              }
            </>
          )
        }
      </div>
    </>
  );
};