// custom hook para traer la data de la orden de compra para crearla

import { useState, useContext, useEffect } from "react";


// import notification context
import { NotificationContext } from "../../../context/NotificationContext.jsx";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";




export const useFetchOrderDetail = (END_POINT = "", token = "", CodSol = "", lang = "") => {

  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, showModal: false, questionAct: "", btnSepa: false, modalChangPart: false, CodSol: 0, CodDet: 0, reaload: false, redirect: false, modalFilter: false, sending: false });
  const [form, setForm] = useState({ dynamic: "1", almacen: "", tipAprobacion: "", empresa: "", via: "", codSuplidor: "", nomSuplidor: "", contactSuppl: "", shipTo: "", contactEmpresa: "", aprobadoPor: "", incoterm: "", observa: "", comment: "", codZona: 0, facturarA: "", primaryAction: 0 });
  const [error, setError] = useState({ dynamic: null, almacen: null, via: null, empresa: null, codSuplidor: null, incoterm: null, tipAprobacion: null });
  const [controller, setController] = useState(null); // controlador para cancelar la peticion al servidor
  const [stateSol, setStateSol] = useState({}); // Estado para colocar la solicitud
  const [partes, setPartes] = useState([]); // Estado para colocar las partes de la solicitud
  const [otherInfo, setOtherInfo] = useState({ empEnvia: [], contactEmpr: [], depart: [], contactSupp: [] });


  // GET the react translation
  const [t] = useTranslation("global");

  const navigate = useNavigate();

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);

  // funcion para limpiar los comentario
  const handleClearComment = () => {
    setForm((val) => ({ ...val, comment: "" }));
  };


  // funcion para recargar cuando se cambie una parte
  const handleReloadData = (newVal = false) => {
    setState((val) => ({ ...val, reaload: newVal }));
  };


  // funcion para abrir el modal de modificar las partes
  const handleOpenCloseModifyPart = (CodSol = 0, CodDet = 0) => {
    setState((val) => ({ ...val, modalChangPart: !state.modalChangPart, CodDet, CodSol }));
  };


  // funcion para agregar el nombre y el codigo del suplidor
  const onAddSupplierData = (CodSupp = 0, NameSupp = "") => {
    setForm((val) => ({ ...val, codSuplidor: CodSupp, nomSuplidor: NameSupp }));
    if (CodSupp !== 0) {
      setError((val) => ({ ...val, codSuplidor: null }));
    }
  };


  // funcion para abrir el modal de filtrar el suplidor
  const handleOpenCloseModalSupp = () => {
    setState((val) => ({ ...val, modalFilter: !state.modalFilter }));
  };


  // funcion para actualizar el estado de enviar la data al servidor
  const handleSendData = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };

  // funcion para modificar el exportar y el separar
  const handleChangeValPart = (newVal, CodDet = 0, propN = "") => {
    // Values: Exportar, Separar
    setPartes((val) => {
      return val.map((part) => {
        if (CodDet === part.CodDetSolRepuesto) {
          return { ...part, [propN]: newVal };
        }
        return part;
      });
    });
  };


  // funcion para abrir el modal con las diferentes opciones
  const handlePerformAct = (id = 0) => {

    let FinalMsg = null;
    const RequiredFields = ["dynamic", "almacen", "via", "empresa", "codSuplidor", "incoterm", "tipAprobacion"];

    if ([1, 4].includes(id)) { // guardar la orden

      const EmptyFields = [];

      if ([1, 4].includes(id)) { // Si es guardar la orden o enviar a dynamic
        for (const fields in form) {
          if (RequiredFields.includes(fields) && form[fields] === "") {
            setError((val) => ({ ...val, [fields]: "err" }));
            EmptyFields.push(fields);
          } else {
            setError((val) => ({ ...val, [fields]: null }));
          }
        }

        if (EmptyFields.length > 0) {
          return handleNotify(true, t("LogisticViewComp.compl_camp_vacios_marc"), true);
        }

      }

      if (id === 1) { // guardar la orden
        FinalMsg = t("LogisticViewComp.seguro_guar_cambios");
        if (!stateSol?.NumOrden) {
          FinalMsg = t("LogisticViewComp.seguro_crear_orden_comp");
        }
      }

    }

    if (id === 2) { // Devolver al soporte 
      FinalMsg = t("LogisticViewComp.devolver_apro_soporte");
    }

    if (id === 3) { // Devolver administrativamente
      FinalMsg = t("RequestPartsView.seguro_enviar_apro_admi");
    }

    if (id === 4) { // Enviar al dynamic
      const EmptyItems = [];
      if (Number(form.dynamic) === 1) {
        for (const part of partes) {
          if (!part.TipArticulo && !part.Existe) {
            EmptyItems.push(part.CodDetSolRepuesto);
          }
        }
      }

      const ErrorMsg = lang === "en" ? `There are ${EmptyItems.length} parts that you must assign the item type to.` : `Hay ${EmptyItems.length} partes que debe asignar el tipo de artículo.`;
      if (EmptyItems.length > 0) {
        return handleNotify(true, ErrorMsg, true);
      }

      FinalMsg = t("LogisticViewComp.seguro_envi_siguiente_etapa");
      if (Number(form.dynamic) === 1) {
        FinalMsg = t("LogisticViewComp.seguro_envi_sist_admi");
      }

    }

    if (id === 5) { // separar un item de la orden
      FinalMsg = t("LogisticViewComp.separar_item_pregunta");
    }

    setState((val) => ({ ...val, questionAct: FinalMsg, showModal: true }));
    setForm((val) => ({ ...val, primaryAction: id }));
  };


  // funcion para abrir y cerrar el modal
  const handleCloseOpenModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
    const RequiredFields = ["dynamic", "almacen", "via", "empresa", "codSuplidor", "incoterm", "tipAprobacion"];

    if (RequiredFields.includes(name)) {
      setError((val) => ({ ...val, [name]: null }));
    }
  };


  // Funcion para traer los datos del servidor
  const fetchDataToServer = async (act = "") => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        if (data?.msg) {
          handleNotify(true, data?.msg, false, true);
          if (data?.redirect) {
            navigate(-1, { replace: true });
          }
        }

        setStateSol(data?.solRepuesto[0]);
        setOtherInfo((val) => ({ ...val, empEnvia: data?.empEnvia, contactEmpr: data?.contactEmpEnvia, depart: data?.depart, contactSupp: data?.contacSuppli }));
        setPartes(data?.detSolRep);
        handleReloadData();

        const Form = data?.solRepuesto[0];

        if (act === "") {
          // cargar la infor de la orden en el estado
          setForm((val) => ({
            ...val, dynamic: Form?.EsOrdenLS ?? "", almacen: Form?.CodDepartamento ?? "", tipAprobacion: Form?.IdTipAprobacion ?? "", empresa: Form?.CodEmpresa ?? "", via: Form?.IdVia ?? "", codSuplidor: Form?.CodProveedor ?? "",
            contactSuppl: Form?.CodConProveedor ?? "", shipTo: Form?.CodEmpEnvia ?? "", contactEmpresa: Form?.CodConEmpEnvia ?? "", aprobadoPor: Form?.Aprobado ?? "", incoterm: Form?.Incoterm ?? "", observa: Form?.ObsOrden ?? "",
            codZona: Form?.CodZona, facturarA: Form?.FacturarA ?? "", nomSuplidor: Form?.NomProveedor ?? ""
          }));
        } else {
          setForm((val) => ({ ...val, observa: Form?.ObsOrden ?? "" }));
        }
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) { // si el usuario no esta logeado o venció su token
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      } else if (response.status === 500) {
        handleNotify(true, data?.msg, true);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataToServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSol, lang]);


  // useEffect para que aparezca el boton para separar los items de una orden
  useEffect(() => {
    const SepararItems = partes.filter((item) => item.Separar);

    setState((val) => ({ ...val, btnSepa: SepararItems.length > 0 }));

  }, [partes]);


  // useEffect para recargar la data
  useEffect(() => {
    if (state.reaload) {
      fetchDataToServer("1");

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.reaload]);



  return {
    state,
    form,
    error,
    stateSol,
    otherInfo,
    partes,
    handleChangeFormVal,
    handleCloseOpenModal,
    handlePerformAct,
    handleSendData,
    handleChangeValPart,
    handleOpenCloseModalSupp,
    onAddSupplierData,
    handleOpenCloseModifyPart,
    handleReloadData,
    handleClearComment
  };
};