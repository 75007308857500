// componente para solicitar la data de seguimiento de logistica

// imports
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "../../../context/NotificationContext.jsx";




export const useFetchFollowUp = (urls = {}, token = "", lang = "", params) => {

  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, errorServer: false, unauthorized: false, showModal: false, showBtnAdd: false, showModalUpdate: false, loadingData: false, modalSearch: false, total: 0, moreData: false, update: false });
  const [orderList, setOrderList] = useState([]); // para setear el estado de las ordenes
  const [orderUpdate, setOrderUpdate] = useState([]); // estado para las ordenes que se van actualizar
  const [currentPag, setCurrentPag] = useState(1);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [pageSize] = useState(20);
  const [zonas] = useState({ RD: 1, CARIBE: 6, GMDH: 7 });


  const { handleNotify } = useContext(NotificationContext);


  // react translation
  const [t] = useTranslation("global");

  // GET the react navigate
  const navigate = useNavigate();


  // funcion para actualizar el estado de actualizacion
  const handleChangeUpdate = (newVal = false) => {
    setState((val) => ({ ...val, update: newVal }));
  };


  // funcion para setear la nueva pagina
  const handleSetNewState = (newList = [], total = 0, moreD = false, newP = 0) => {
    setOrderList(newList);
    setCurrentPag(newP);
    setState((val) => ({ ...val, moreData: moreD, total }));
  };

  // funcion para setear el orderUpdate
  const handleSetNewOrderUpdate = () => {
    setOrderUpdate([]);
  };

  // funcion para actualizar el enviar la data
  const handleSendServer = (newVal = false) => {
    setState((val) => ({ ...val, loadingData: newVal }));
  };

  // funcion para ocultar el boton de buscar
  const handleCloseOpenBtnAdd = () => {
    setState((val) => ({ ...val, showBtnAdd: !state.showBtnAdd }));
  };

  // funcion para cerrar el modal de actualizar
  const handleCloseOpenModalUpd = () => {
    setState((val) => ({ ...val, showModalUpdate: !state.showModalUpdate }));
  };


  // funcion para abrir cerrar el modal de busqueda
  const handleCloseOpenSearch = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  // funcion para cambiar la cantidad en las partes
  const handleChangePartVal = (newVal = 0, CodSol = 0, CodDet = 0) => {
    if (isNaN(newVal)) {
      return;
    }

    setOrderUpdate((val) => {
      return val.map((part) => {
        if (CodSol === part.CodSolRepuesto && CodDet === part.CodDetSolRepuesto) {
          if (newVal > part.Cantidad) {
            return part;
          }
          return { ...part, CantMover: newVal };
        }
        return part;
      });
    });
  };

  // funcion para cambiar el estado de las partes
  const setNewValForParts = () => {
    setOrderList((val) => {
      return val.map((part) => {
        return { ...part, Mover: false };
      });
    });
  };


  // funcion para colocar en true al mover una parte
  const handleChangeMove = (CodDet = 0, CodSol = 0, newVal = false) => {

    let BTN = true;

    const UpdatedOrders = orderList.map((part) => {
      if (CodDet === part.CodDetSolRepuesto && CodSol === part.CodSolRepuesto) {
        if (orderUpdate.length > 0) {
          if (orderUpdate[0]?.IdEtapa !== part.IdEtapa) {
            // enviar la notificacion al usuario
            handleNotify(true, t("RequestPartsView.etapa_distin"), true);
            BTN = false;
            setState((val) => ({ ...val, showBtnAdd: false }));
            return part;
          }
        }
        return { ...part, Mover: newVal };
      }
      return part;
    });

    // filtrar las que se van a mover
    const FilteredOrders = UpdatedOrders.filter((ord) => ord.Mover);


    if (BTN) {
      setState((val) => ({ ...val, showBtnAdd: FilteredOrders.length > 0 }));
    } else {
      setState((val) => ({ ...val, showBtnAdd: false }));
    }

    setOrderUpdate(FilteredOrders);

    setOrderList(UpdatedOrders);
  };


  // Funcion para realizar la peticion al servidor
  const fethDataServer = async (action = "") => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getFollowUpOrders}?zona=${params.zona ?? ""}&etapa=${params.etapa ?? ""}&suplidor=${params.suplidor ?? ""}&numOrden=${params.numOrden ?? ""}&numPart=${params.numPart ?? ""}&soNumber=${params.soNumber ?? ""}&guiaDesp=${params.guiaDesp ?? ""}&fecDesp=${params.fecDesp ?? ""}&division=${params.division ?? ""}&linea=${params.linea ?? ""}&modalidad=${params.modalidad ?? ""}&cliente=${params.cliente ?? ""}&workflow=${params.workflow ?? ""}&language=${lang}&page=${currentPag}&pageSize=${pageSize}&action=1&filt=${params.filter ?? "0"}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      handleChangeUpdate();

      if (response.status === 200) {
        setCurrentPag(currentPag + 1);
        if (data?.orders.length < pageSize) {
          setCurrentPag(1);
        }

        if (action === "1") { // primer render
          setOrderList([...orderList].concat(data?.orders));
        } else { // segundo render
          setOrderList([]);
          setOrderList(data?.orders);
        }


        setState((val) => ({ ...val, dataVisible: true, moreData: data?.orders.length < pageSize, total: data?.Total }));
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        // esto es si el usuario no tiene permisos para ver esto
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: true }));
        // no se encontró nada
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      } else if (response.status === 503) {
        controller.abort();
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect declaration
  useEffect(() => {

    fethDataServer("1");

    // Cambiar el titulo del documento
    document.title = t("LogisticView.followOrder");

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);

  // useEffect para recargar la data
  useEffect(() => {
    if (state.update) {
      fethDataServer("2");

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.update]);


  // retornar
  return {
    state,
    orderList,
    orderUpdate,
    pageSize,
    zonas,
    handleChangeMove,
    handleCloseOpenSearch,
    handleChangePartVal,
    handleCloseOpenModalUpd,
    handleCloseOpenBtnAdd,
    handleSendServer,
    handleSetNewState,
    handleSetNewOrderUpdate,
    fethDataServer,
    handleChangeUpdate,
    setNewValForParts
  };
};