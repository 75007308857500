// custom hook para el detalle de la solicitud de repuesto

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";




export const useFetchSolRep = (CodSolR = "", token = "", urls = {}, lang = "") => {

  // state definition
  const [controller, setController] = useState(null); // Estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, showModal: false, question: "", dataVisible: false, addPartComp: false, reload: false, update: false, sending: false });
  const [form, setForm] = useState({ priority: "", tipSolicitud: "", action: 0, CodSol: 0, CodDet: 0 });
  const [solState, setSolState] = useState({});
  const [parts, setParts] = useState([]);
  const [EmpPerm, setEmpPerm] = useState([]);

  // GET the react translation
  const [t] = useTranslation("global");



  // GET the useNavigate
  const navigate = useNavigate();


  // funcion para manejar el enviar al servidor
  const handleSendServ = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };


  // funcion para el boton de enviar y eliminar
  const handlePerformAct = (id = 0, CodDet = 0, CodSol = 0) => {
    const QUESTION = {
      1: t("LogisticViewComp.enviar_apro_tec"),
      2: t("LogisticViewComp.eliminar_solicitud"),
      3: t("LogisticViewComp.eliminar_pn")
    };
    setState((val) => ({ ...val, showModal: true, question: QUESTION[id] }));
    setForm((val) => ({ ...val, action: id, CodDet, CodSol }));
  };


  // funcion para abrir y cerrar el modal de confirmar
  const handleOpenCloseModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para abrir y cerrar el modal de agregar parte
  const handleOpenCloseAddPart = () => {
    setState((val) => ({ ...val, addPartComp: !state.addPartComp }));
  };


  // funcion para modificar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para actualizar desde fuera del componente
  const handleUpdateFromChild = (newVal = false) => {
    setState((val) => ({ ...val, update: newVal }));
  };


  // funcion para solicitar los datos al servidor
  const fetchDataServer = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.requestDetail}/${CodSolR}/search?language=${lang}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const { solRepuesto, detSolRepuesto, EmpPerm } = await response.json();

      // VALIDACIONES
      if (response.status === 200) {
        setSolState(solRepuesto[0]);
        setParts(detSolRepuesto);
        setEmpPerm(EmpPerm);
        handleUpdateFromChild();
        setForm((val) => ({ ...val, priority: solRepuesto[0]?.Prioridad.toString() ?? "", tipSolicitud: solRepuesto[0]?.IdTipSolicitud ?? "" }));
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) { // verificar si el token aún es valido
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 400) {
        // bad request, enviar mensaje al usuario
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar los datos al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSolR, lang]);


  // useEffect para recargar la data
  useEffect(() => {

    if (state.update) {
      fetchDataServer();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }

  }, [state.update]);


  return {
    state,
    form,
    solState,
    parts,
    EmpPerm,
    handlePerformAct,
    handleOpenCloseAddPart,
    handleChangeFormVal,
    handleOpenCloseModal,
    handleUpdateFromChild,
    handleSendServ
  };
};