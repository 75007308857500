import React, { useContext } from "react";

// REACT ROUTER IMPORTATION
import { Link, useNavigate, useParams } from "react-router-dom";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { PartDetailModify } from "../../components/logistic-components/PartDetailModify.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { SearchSupplier } from "../../components/order-detail/SearchSupplier.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";


// icons importation
import { PDFIcon, SendIcon } from "../../components/icons/AppIcons.jsx";


// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { formatCurrency, getTheToken, renderDate, renderIdTipSolicitud, renderTemperature } from "../../global-functions/globalFunctions.js";

// React translation
import { useTranslation } from "react-i18next";

// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


// import custom hook
import { useFetchOrderDetail } from "../../components/customs/order-detail/useFetchOrderDetail.js";



export const OrderDetailView = () => {


  // GET the lang context
  const { lang } = useContext(LangContext);

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // React translation
  const [t] = useTranslation("global");

  const { id } = useParams();


  // End point
  const EndPoint = `${urls.orderDetail}/${id}/search?language=${lang}`;


  // GET the useFetch custom hook
  const { form,
    state,
    error,
    otherInfo,
    stateSol,
    partes,
    handleChangeFormVal,
    handleCloseOpenModal,
    handlePerformAct,
    handleSendData,
    handleChangeValPart,
    handleOpenCloseModalSupp,
    onAddSupplierData,
    handleOpenCloseModifyPart,
    handleReloadData,
    handleClearComment } = useFetchOrderDetail(EndPoint, getTheToken().token, id, lang);




  // GET useNavigate()
  const navigate = useNavigate();


  // Empresa 
  const ORDEN_COMPRA = 20; // Etapa de orden de compra

  let totalOrden = 0; // esta variable para tener el total de la orden de compra


  // IdTipAprobaciones que incluyen aprobacion zse
  const APROBACIONES = ["2", "6", "7"];

  // console.log(supplierFoundRes)


  // funcion para descargar la aprobacion del soporte
  const onDownloadSoporteDoc = async (print = "", action = "") => {
    try {
      const response = await fetch(`${urls.orderDetail}?language=${lang}&codSolRep=${id}&action=${action}&print=${print}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlance oculto
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = blobURL;
        a.download = action === "1" ? `Zone support approval ${id}` : `Purchase order`;

        document.body.appendChild(a);

        // simular click en el enlace para iniciar la descargar
        a.click();

        // setNotification((val) => ({ ...val, show: true, msg: t("General_Comp.descarga_doc"), great: true }));

        // limpiar la url y el enlace de la descarga
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      }

    } catch (error) {
      console.log(error);
    }
  };

  // Manejador del submit para enviar el formulario
  const onSubmitCreateOrd = async (event) => {
    event.preventDefault();

    const finalObj = { form, partes };
    try {
      handleSendData(true);
      const response = await fetch(`${urls.orderDetail}/${id}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 201) { // Cuando la orden se crea por primera vez
        handleCloseOpenModal();
        handleNotify(true, data?.msg, false, true);
        handleReloadData(true);
      } else if (response.status === 200) { // Cuando se actualiza informacion
        handleCloseOpenModal();
        if (data?.redirect) {
          navigate("/logistic-principal");
        }
        handleClearComment();
        handleNotify(true, data?.msg, false, true);
      }

      handleSendData();

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        state.modalFilter && (
          <SearchSupplier onClose={handleOpenCloseModalSupp} CodSol={id} lang={lang} token={getTheToken().token} onAddSupp={onAddSupplierData} />
        )
      }
      {
        // error 403
        state.unauthorized && (<Error403View />)
      }
      {/* Components loading */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (

            <form onSubmit={onSubmitCreateOrd}>
              {/* FILA 1 */}
              <div className="row-flex">
                {/* DYNAMICS */}
                <div className="col-8 col-sm-30">
                  <strong>Dynamics</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.dynamic && "red" }}
                    name="dynamic"
                    value={form.dynamic}
                    onChange={({ target }) => handleChangeFormVal("dynamic", target.value)}>
                    <option value=""></option>
                    <option value="1">{t("RequestPartsView.si")}</option>
                    <option value="0">No</option>
                  </select>
                </div>
                {/* NUMERO ORDEN DYNAMICS */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.orden_dyn")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <span>{stateSol?.NumOrdenLS}</span>
                </div>
                {/* SELECCIONAR EL ALMACEN */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.alma")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.almacen && "red" }}
                    name="warehouse"
                    value={form.almacen}
                    onChange={({ target }) => handleChangeFormVal("almacen", target.value)}>
                    <option value=""></option>
                    {
                      otherInfo.depart && otherInfo.depart.map((dep) => (
                        <option key={dep.CodDepartamento} value={dep.CodDepartamento}>{dep.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* ORDEN DE COMPRA # */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.orden_compra")}</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <h5 style={{ color: 'var(--first-color)' }}>{stateSol?.NumOrden}</h5>
                </div>
                {/* SOLICITUD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <h5>
                    <Link className="link-tag" to={`/request-part-detail/${id}`}>{id}</Link>
                  </h5>
                </div>
                {/* CLIENTE */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <Link className="link-tag" to={`/customers/customer-detail/${stateSol?.CodCliente}`}>{stateSol?.NomCliente}</Link>
                </div>
              </div>

              {/* FILA 3 */}
              <div className="row-flex">
                {/* FECHA/HORA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <span>{renderDate(stateSol?.FecIngreso, lang)}</span>
                </div>
                {/* ID SERVICIO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <h5>
                    <Link className="link-tag" to={`/service/service-call/${stateSol?.CodServicio}`}>{stateSol?.CodServicio}</Link>
                  </h5>
                </div>
                {/* EQUIPO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod text-hidden-over">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${stateSol?.CodEquipo}`}>{stateSol?.Equipo}</Link>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* TIPO DE APROBACION */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.tip_aproba")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.tipAprobacion && "red" }}
                    name="typeApproval"
                    value={form.tipAprobacion}
                    onChange={({ target }) => handleChangeFormVal("tipAprobacion", target.value)}>
                    <option value=""></option>
                    <option value="0">Stock</option>
                    <option value="3">{t("RequestPartsView.designado")}</option>
                    <option value="6">{t("RequestPartsView.garan_ventas")}</option>
                    <option value="9">{t("RequestPartsView.export")}</option>
                    <option value="1">{t("RequestPartsView.activo_fij")}</option>
                    <option value="4">FMI/STB</option>
                    <option value="7">{t("RequestPartsView.garan_sopor")}</option>
                    <option value="10">{t("RequestPartsView.compr_local")}</option>
                    <option value="2">{t("RequestPartsView.contr")}</option>
                    <option value="5">Missing</option>
                    <option value="8">{t("RequestPartsView.gasto")}</option>
                    <option value="11">{t("RequestPartsView.garantiaFoiFoa")}</option>
                  </select>
                </div>
                {/* TIPO DE SOLICITUD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.tip_sol")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <span>{renderIdTipSolicitud(stateSol?.IdTipSolicitud, lang)}</span>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                {/* Aprobacion del soporte */}
                <div className="col-8 col-sm-30">
                  <strong>{t("LogisticViewComp.aproba_soporte")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  {/* Si no se ha cargado la aprobacion */}
                  {
                    !stateSol?.Soporte && APROBACIONES.includes(form.tipAprobacion) && (
                      <span className="required">{t("LogisticViewComp.aproba_soporte_no_cargada")}</span>
                    )
                  }
                  {/* Si ya se cargó la aprobacion */}
                  {
                    stateSol?.Soporte && (
                      <button type="button" className="btn-pdf" onClick={() => onDownloadSoporteDoc("1", "1")}>
                        <PDFIcon />
                      </button>
                    )
                  }
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* ETAPA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.eta")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <span style={{ color: 'var(--green-color', fontWeight: 'bold' }}>{stateSol?.NomEtapa}</span>
                </div>
                {/* EMPRESA */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.empresa")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.empresa && "red" }}
                    name="company"
                    value={form.empresa}
                    onChange={({ target }) => handleChangeFormVal("empresa", target.value)}>
                    <option value=""></option>
                    <option value="GLOBL">Global Medica</option>
                    <option value="GMDH">GMD Healthcare Ltd</option>
                  </select>
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* VIA */}
                <div className="col-8 col-sm-30">
                  <strong>Via</strong>
                </div>
                <div className="col-9 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.via && "red" }}
                    name="via"
                    value={form.via}
                    onChange={({ target }) => handleChangeFormVal("via", target.value)}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.aerea")}</option>
                    <option value="1">{t("RequestPartsView.maritima")}</option>
                    <option value="2">{t("RequestPartsView.terrestre")}</option>
                  </select>
                </div>
                {/* SO */}
                <div className="col-9 col-sm-30">
                  <strong>SO / SOA</strong>
                </div>
                <div className="col-7 col-sm-70-mod">
                  <span></span>
                </div>
                {/* PRIORIDAD */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.prio")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <span className={renderTemperature(stateSol?.Prioridad)}></span>
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* SUPLIDOR */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.supli")}</strong></div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    style={{ borderColor: error.codSuplidor && "red" }}
                    className="input-text-control"
                    type="text"
                    name="suppli"
                    onFocus={handleOpenCloseModalSupp}
                    value={form.nomSuplidor}
                    readOnly />
                </div>
                {/* CONTACTO */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.contact")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="contact"
                    className={form.codSuplidor === "" ? "wrapper" : ""}
                    disabled={form.codSuplidor === ""}
                    value={form.contactSuppl}
                    onChange={({ target }) => handleChangeFormVal("contactSuppl", target.value)}>
                    <option value=""></option>
                    {
                      otherInfo.contactSupp && otherInfo.contactSupp.filter((filtCont) => filtCont.CodProveedor === form.codSuplidor).map((cont) => (
                        <option key={cont.CodConProveedor} value={cont.CodConProveedor}>{cont.Nombre} {cont.Apellido}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* SHIP TO */}
                <div className="col-8 col-sm-30">
                  <strong>Ship To</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <select
                    name="shipTo"
                    value={form.shipTo}
                    onChange={({ target }) => handleChangeFormVal("shipTo", target.value)}>
                    <option value=""></option>
                    {
                      otherInfo.empEnvia && otherInfo.empEnvia.map((emp) => (
                        <option key={emp.CodEmpEnvia} value={emp.CodEmpEnvia}>{emp.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* CONTACTO EMPRESA */}
                <div className="col-9 col-sm-30">
                  <strong>{t("RequestPartsView.contact")}</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    name="contactEmpr"
                    className={form.shipTo === "" ? "wrapper" : ""}
                    disabled={form.shipTo === ""}
                    value={form.contactEmpresa}
                    onChange={({ target }) => handleChangeFormVal("contactEmpresa", target.value)}>
                    <option value=""></option>
                    {
                      otherInfo.contactEmpr && otherInfo.contactEmpr.map((cont) => (
                        <option key={cont.CodConEmpEnvia} value={cont.CodConEmpEnvia}>{cont.NomContacto}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* APROBADO POR */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.apro_por")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    type="text"
                    name="approvedBy"
                    value={form.aprobadoPor}
                    onChange={({ target }) => handleChangeFormVal("aprobadoPor", target.value)} />
                </div>
                {/* INCOTERM */}
                <div className="col-9 col-sm-30">
                  <strong>Incoterm</strong>
                </div>
                <div className="col-39-29 col-sm-70-mod">
                  <select
                    style={{ borderColor: error.incoterm && "red" }}
                    name="incoterm"
                    value={form.incoterm}
                    onChange={({ target }) => handleChangeFormVal("incoterm", target.value)}>
                    <option value=""></option>
                    <option value="FOB">FOB</option>
                    <option value="EXW">EXW</option>
                    <option value="CIF">CIF</option>
                    <option value="DDP">DDP</option>
                    <option value="DDU">DDU</option>
                  </select>
                </div>
              </div>

              {/* FILA */}
              <div className="row-flex">
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.facturar_a")}</strong>
                </div>
                <div className="col-36-51 col-sm-70-mod">
                  <input
                    className="input-text-control"
                    name="billTo"
                    type="text"
                    value={form.facturarA}
                    onChange={({ target }) => handleChangeFormVal("facturarA", target.value)} />
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* FALLA */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-6 col-sm-70-mod">
                  <span style={{ color: 'var(--danger-color)' }}>{stateSol?.Falla}</span>
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.obser")}</strong>
                </div>
                <div className="col-87 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="observation"
                    value={form.observa}
                    onChange={({ target }) => handleChangeFormVal("observa", target.value)}
                    autoComplete="off" />
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex">
                {/* INSTRUCCIONES DE DESPACHO */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.instruc_despa")}</strong>
                </div>
                <div className="col-sm-70-mod">
                  <h4 style={{ color: 'red' }}>{partes[0]?.Despacho}</h4>
                </div>
              </div>

              {/* FILA 13 */}
              <div className="row-flex">
                {/* COMENTARIO */}
                <div className="col-8 col-sm-30">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="col-87 col-sm-70-mod">
                  <textarea
                    className="textarea-description"
                    name="comment"
                    value={form.comment}
                    onChange={({ target }) => handleChangeFormVal("comment", target.value)}
                    autoComplete="off"
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              {/* TABLA DE LAS PARTES */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>{t("TableParts.exportar")}</th>
                      <th>WorkFlow</th>
                      <th>{t("TableParts.precio_unit")}</th>
                      <th>{t("TableParts.itbis")}</th>
                      <th>{t("TableParts.precio_total")}</th>
                      <th>{t("LogisticViewComp.separar")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part) => {

                        const total = part.Precio * part.Cantidad * (1 + part.Itbis / 100);

                        totalOrden += total;

                        return (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center">
                              <button type="button" className="btn-link-tag" onClick={() => handleOpenCloseModifyPart(part.CodSolRepuesto, part.CodDetSolRepuesto)}>
                                {part.CodDetSolRepuesto}
                              </button>
                            </td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td><Link className={part.Class} to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link></td>
                            <td>{part.Descripcion}</td>
                            <td>
                              <select
                                name={`export-${part.CodDetSolRepuesto}`}
                                value={part.Exportar}
                                onChange={({ target }) => handleChangeValPart(target.value, part.CodDetSolRepuesto, "Exportar")}>
                                <option value=""></option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                              </select>
                            </td>
                            <td className="txt-center">{part.WorkFlow}</td>
                            <td className="txt-right">
                              {formatCurrency(part.Precio, 'USD')}
                            </td>
                            <td className="txt-right">
                              {part.Itbis}
                            </td>
                            <td className="txt-right">
                              {formatCurrency(total, 'USD')}
                            </td>
                            <td className="txt-center">
                              <input
                                className="input-control-checkbox"
                                type="checkbox"
                                name={`separate-${part.CodDetSolRepuesto}`}
                                checked={part.Separar}
                                onChange={({ target }) => handleChangeValPart(target.checked, part.CodDetSolRepuesto, "Separar")} />
                            </td>
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <td style={{ fontWeight: 'bold' }} className="txt-center">Total</td>
                      <td className="txt-right" colSpan={8} style={{ fontWeight: 'bold', color: 'var(--money-color)' }}>{formatCurrency(totalOrden, 'USD')}</td>
                      {
                        state.btnSepa && (
                          <td className="row-flex align-c justify-center fade-animation" onClick={() => handlePerformAct(5)}>
                            <button type="button" className="btn-delivery" title="Separar item de la orden.">
                              <SendIcon fillP="var(--first-color)" />
                            </button>
                          </td>
                        )
                      }
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around group-mobile m-y-medium">
                <button type="button" className="btn-warning" onClick={() => handlePerformAct(2)}>{t("PartModify.dev_soporte")}</button>
                <button type="button" className="btn-info" onClick={() => handlePerformAct(3)}>{t("PartModify.dev_administr")}</button>
                <button type="button" className="btn-danger">{t("RequestPartsView.elimi")}</button>
                <button type="button" className="btn-primary" onClick={() => handlePerformAct(1)}>{t("RequestPartsView.guar")}</button>
                {
                  stateSol?.NumOrden !== null && stateSol?.IdEtapa === ORDEN_COMPRA && (<button type="button" className="btn-success" onClick={() => handlePerformAct(4)}>{t("RequestPartsView.enviar")}</button>)
                }
                <button type="button" className="btn-info" onClick={() => onDownloadSoporteDoc("1", "2")}>{t("Viaticos.imprimir")} (es)</button>
                <button type="button" className="btn-info" onClick={() => onDownloadSoporteDoc("2", "2")}>{t("Viaticos.imprimir")} (en)</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (

                  <ModalConfirm onClose={handleCloseOpenModal} question={state.questionAct} sending={state.sending} />
                )
              }
            </form>
          )
        }

        {
          state.modalChangPart && (
            <PartDetailModify
              solRepuesto={state.CodSol}
              codDetSolRepuesto={state.CodDet}
              onClose={handleOpenCloseModifyPart}
              onReload={handleReloadData} />
          )
        }

      </div>
    </>
  );
};